<div class="row">
  <div class="col-12 mt-3">
    <div
      class="table-responsive p-3"
      *ngIf="values | async as response;"
    >
      <table
        class="table table-bordered align-middle"
        aria-describedby="default table"
      >
        <thead>
          <th scope="col">
            {{ firstColumn }}
          </th>
          <th scope="col" *ngFor="let column of columns">
            {{ column.name }}
          </th>
          <th scope="col" *ngIf="hasOptions">Options</th>
        </thead>
        <tbody class="table-group-divider" *ngIf="(response.total !=0 || response.data.length !=0 ) else noDat">
          <tr *ngFor="let item of response.data">
            <td *ngFor="let col of mColumns">
              <ng-container *ngIf="col.propertyName == 'lowerRounding'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'canCreateAccount'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'canLogin'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'isDefault'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'firstConnexion'">
                <span  *ngIf="!item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': !item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'flag'">
                 <span>
                   <img
                     class="w-full h-full rounded-full"
                     [src]="download(item[col.propertyName])" alt="" [width]="40"
                     [height]="30"
                     alt="flag">
                 </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'image'">
                 <span>
                   <img
                     style=" border-radius: 50%;"
                     *ngIf="item[col.propertyName] != undefined"
                     class="rounded-full"
                     [src]="download(item[col.propertyName])" alt="" [width]="70"
                     [height]="70"
                     alt="avatar">

                   <img style=" border-radius: 50%;"
                        *ngIf="item[col.propertyName] == undefined"
                        [width]="70"
                        [height]="70" class="b-r-10" src="../assets/images/user.jpg" alt="">
                 </span>
              </ng-container>


            <ng-container *ngIf="col.propertyName == 'updateAt'">
                <span>
                  {{item[col.propertyName] | date}}
                 </span>
            </ng-container>
              <ng-container *ngIf="col.propertyName == 'dateOfBirth'">
                <span>
                  {{item[col.propertyName] | date}}
                 </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName != 'flag'
                &&col.propertyName !='image'
                &&col.propertyName !='lowerRounding'
                &&col.propertyName !='firstConnexion'
                && col.propertyName !='updateAt'
                && col.propertyName !='dateOfBirth'
                && col.propertyName !='canCreateAccount'
                 && col.propertyName !='canLogin'
                    && col.propertyName !='isDefault'">
                 <span>
                    {{ item[col.propertyName] }}
                   </span>
              </ng-container>


            </td>



            <td *ngIf="hasOptions">
              <a
                title="Modifier"
                style="margin-right: 5%"
                *ngIf="isEditable || isOnlyForRoot"
                (click)="edit(item)"
                >
                <span
                  style="color: blue"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen055.svg'"
                  class="svg-icon svg-icon-3"
                ></span>
               </a>

              <a

                title="Activer/Désactiver"
                style="margin-right: 5%"
                *ngIf="(isHideable || isOnlyForRoot)"
                (click)="hideOrShow(item[idRef])"
                >
                <span
                  style="color: plum"
                  *ngIf="item.status === generalStatus.ACTIVE || item.status === userStatus.ACTIVE"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen037.svg'"
                  class="svg-icon svg-icon-3"
                ></span>
                <span
                  style="color: plum"
                  *ngIf="item.status === userStatus.BLOCKED || generalStatus.BLOCKED"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen040.svg'"
                  class="svg-icon svg-icon-3"
                ></span>

                </a>

              <a
                title="Supprimer"
                style="margin-right: 5%"
                *ngIf="isDeletable || isOnlyForRoot"
                (click)="delete(item)"
                >
                <span
                  style="color: red"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen027.svg'"
                  class="svg-icon svg-icon-3"
                ></span></a>

              <a
                title="Détails"
                style="margin-right: 5%"
                *ngIf="hasDetails"
                (click)="showDetails(item)"
                > <span
                style="color: darkorchid"
                [inlineSVG]="'./assets/images/icons/duotune/general/gen022.svg'"
                class="svg-icon svg-icon-3"
              ></span></a>
            </td>

          </tr>
        </tbody>
      </table>

      <ngb-pagination
        class="d-flex justify-content-center mt-3"
        [(page)]="page"
        (pageChange)="onPageChange(page)"
        [pageSize]="pageSize"
        [collectionSize]="response.total"
        [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
      ></ngb-pagination>

    </div>
  </div>
</div>

<ng-template #noDat>

  <tbody>
  <tr>
    <td class="text-center card-label fw-bolder fs-3" colspan="10">Aucune donnée trouvée</td>
  </tr>
  </tbody>

</ng-template>
