<div class="card-body">
  <span
    [inlineSVG]="svgIcon"
    class="svg-icon svg-icon-2x svg-icon-3x ms-n1"
    [ngClass]="'svg-icon-' + iconColor"
  ></span>
  <div [ngClass]="'text-inverse-' + color" class="fw-bolder fs-2 mb-2 mt-5">
    {{ title }}
  </div>

  <div class="fw-bold" [ngClass]="'text-inverse-' + color + ' fs-7'">
    {{ description }}
  </div>
</div>
