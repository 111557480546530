export enum Status {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum UserStatus {
  CREATED = 'CREATED',
  WAITING_VALIDATION = 'WAITING_VALIDATION',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}
