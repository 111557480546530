/**
 * Here you can add the configuration related to keycloak
 * So we can use this common config for diffrent environment
 */
import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'http://74.208.163.72:8000/',
  realm: 'sendrate',
  clientId: 'sendrate-admin',
};

export default keycloakConfig;
